window.__tnt || (window.__tnt = {});
window.__tnt.pay || (window.__tnt.pay = {});
window.__tnt.pay.method || (window.__tnt.pay.method = {});

// init - runs at form init
// submitPayment - runs when the user clicks the pay button (if this is selected)

__tnt.pay.method.paypal = {
    name: "paypal",
    methodString: "PayPal",
    init: function(pf) {
        var paymentMethods = null,
            outputElement = null,
            paypalButton = null;

        if (pf) {
            paymentMethods = pf.dataset.paymentMethods;
            outputElement = pf.querySelector('.tn-pay-method-alt');
            paypalButton = outputElement.querySelector('.tn-pay-method-paypal');

            if (paymentMethods && paymentMethods.includes('paypal')) {
                if (!paypalButton) {
                    paypalButton = document.createElement('a');
                    paypalButton.id = "paypal-payment-" + pf.dataset.spreedlyContext;
                    paypalButton.classList.add("btn", "btn-lg", "btn-block", "wallet-btn", "tn-pay-method-paypal", "text-center", "tn-pay-method-alt");
                    paypalButton.style = "";
                    paypalButton.innerHTML = '<i class="fab tnt-paypal"></i> PayPal';
                    paypalButton.addEventListener('click',function(ev){
                        __tnt.pay.setPaymentMethod(pf, "paypal", this);

                    });
                }

                outputElement.appendChild(paypalButton);
            } else if (paypalButton) {
                paypalButton.remove();
            }
        }
    },
    submitPayment: function(pf) {
        try{
            var tntPay = null,
                elError = null,
                elErrorMessage = null,
                paypalForm = null;

            if (pf) {
                tntPay = pf.parentElement;
                
                if (tntPay) {
                    paypalForm = tntPay.querySelector('.tnt-payment-paypal-form');
                    elError = tntPay.querySelector('.tnt-payment-error');
                    
                    if(elError){ elErrorMessage = elError.querySelector('.error-message') }
                }
            }

            sessionStorage.setItem('__tnt.subscription.offers.purchaseType', pf.dataset.purchaseType);

            if(paypalForm){
                paypalForm['service_id'].value = pf['service_id'].value;
                paypalForm['rate_id'].value = pf['rate_id'].value;
                paypalForm['email'].value = pf['email'].value;
                paypalForm['password'].value = pf['password'].value;
                paypalForm['password_verify'].value = pf['password_verify'].value;
                paypalForm['postal-postcode'].value = pf['postal-postcode'].value;
                paypalForm['postal_code'].value = pf['postal-postcode'].value;
                paypalForm['postal-country'].value = pf['postal-country'].value;
                paypalForm['country'].value = pf['postal-country'].value;

                let paypalFormData = new FormData(paypalForm);

                try {
                    //let errorLogged = false;
                    fetch('/tncms/subscription/buy_paypal/', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json'
                        },
                        body: paypalFormData
                    })
                    .then(function(response) {
                        return response.json().then(function(data) {

                            if (response.ok) {
                                if (data.redirect_url) {
                                    window.location.href = data.redirect_url;
                                }
                            } else {
                                elErrorMessage.innerHTML = data.message;
                                elError.hidden = false;
                                elError.classList.remove('fade');
                                elError.scrollIntoView(false);

                                throw new Error(data.message);
                            }
                        });
                    })
                    .catch(function(error) {
                        if (typeof error === 'object') {
                            throw error;
                        } else {
                            throw new Error(error);
                        }
                    });
                } catch(error) {
                    elError.hidden = false;
                    elError.classList.remove('fade');
                    elError.scrollIntoView(false);
                    console.error(error);
                }
            }
        } catch(error){ console.log(error) }

    }
};

